import React, { FC } from 'react';
import './login-logo.component.scss';
import logo from '../../../assets/logos/deploi-logo.svg';

const LoginLogo: FC = () => {
	return (
		<div className='logo'>
			<a href={process.env.REACT_APP_DEPLOI_URL} target="_blank" rel="noopener noreferrer">
				<img src={logo} alt="logo" />
			</a>
		</div>
	);
};

export default LoginLogo;
