import React, { useEffect, useState, FC } from 'react';
import './login.styles.scss';
import { useDispatch } from 'react-redux';
import { clearProfileData } from '../../redux/Profile/profile.actions';
import { resetDashboardState } from '../../redux/Dashboard/dashboard.actions';
import {
	clearAuth,
	login,
	setRole,
	setUser,
} from '../../redux/Auth/auth.actions';
import { setAlert } from '../../redux/General/general.actions';
import LoginInputsSection from '../../components/login/login-inputs-section/login-inputs-section.component';
import LoginPhone from '../../components/login/login-phone/login-phone.component';
import * as authApi from '../../api/api.auth';
import LoginLayout from '../../components/general/login-layout/login-layout.component';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';
import { useNavigate } from 'react-router-dom';

const Login: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loginSubmitted, setLoginSubmitted] = useState(false);
	const [code, getCode] = useState('');
	const [credentials, setCredentials] = useState({
		email: '',
		password: '',
	});
	const [resendPhone, setResendPhone] = useState(false);

	useEffect(() => {
		dispatch(clearProfileData());
		dispatch(resetDashboardState());
		dispatch(clearAuth());
		localStorage.clear();
	}, []);

	useEffect(() => {
		if (code.length === 6) {
			handleLogin();
		}
	}, [code]);

	const handleLogin = () => {
		const payload = {
			email: credentials.email,
			verificationCode: code,
		};

		authApi
			.verifyEmail(payload)
			.then(({ data }) => {
				setCredentials({
					email: '',
					password: '',
				});
				dispatch(setRole(data.data.user.role));
				dispatch(setUser(data.data.user));
				dispatch(login());
				navigate('/check', { replace: true });
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.data ? err.data.message : err.message,
						isVisible: true,
					}),
				);
				setLoginSubmitted(false);
			});
	};

	useEffect(() => {
		if (resendPhone) {
			authApi
				.login(credentials)
				.then(() => {
					setResendPhone(false);
				})
				.catch(() => {
					dispatch(
						setAlert({
							type: 'error',
							message: "couldn't resend code",
							isVisible: true,
						}),
					);
				});
		}
	}, [resendPhone]);

	return (
		<LoginLayout>
			<ErrorBoundary applicantWorkerUuid='None'>
				{!loginSubmitted ? (
					<LoginInputsSection
						setLoginSubmitted={setLoginSubmitted}
						setCredentials={setCredentials}
					/>
				) : (
					<LoginPhone
						getCode={getCode}
						setResendPhone={setResendPhone}
						userEmail={credentials.email}
					/>
				)}
			</ErrorBoundary>
		</LoginLayout>
	);
};

export default Login;
